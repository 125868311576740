html,
body {
  width: 100%;
  height: 100%;
  border: none;
  margin: 0px;
  padding: 0px;
}

#root {
  width: 100%;
  height: 100%;
  border: none;
  margin: 0px;
  padding: 0px;
}
